import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const HeroImage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "reporting/herosection"}, name: {eq: "hero-image"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return <img 
    src={data.allFile.edges[0].node.childImageSharp.fluid.src}  
    className="hero-image"
    style={{width: "100%"}}
    />
}

export default HeroImage
