import React, {useState, useEffect} from "react"
import { BinaryCodeWrapper } from "./BinaryCode.styles"

const BinaryCode = ({numCount, binaryStyles, classList}) => {

    const [binary, setBinary] = useState();

    function generateBinary(){
        let returnCharacters = '1';
        const characters = '10 ';
        const charactersLength = characters.length;
        for (var i = 0; i < numCount - 1; i++) {
            returnCharacters += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return returnCharacters;
    }

    useEffect(() => {
        let characters = generateBinary();
        setBinary(characters);
        
        const interval = setInterval(() => {

            const index = Math.floor(Math.random() * (numCount - 1)) + 1;
            const charList = '10 ';
            const replacement = charList.charAt(Math.floor(Math.random() * 3));

            characters = characters.substring(0, index) + replacement + characters.substring(index + 1);
            
            setBinary(characters);
        }, 500);
        return () => clearInterval(interval);
    }, []);

    return(
        <BinaryCodeWrapper
            dangerouslySetInnerHTML={{__html: binary}}
            style={binaryStyles}
            className={classList}
        >
        </BinaryCodeWrapper>
    )
};

export default BinaryCode