import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { ApexxAnalyticsWrapper } from "./ApexxAnalytics.styles";

const ApexxAnalytics = () => (
    <ApexxAnalyticsWrapper className="px-4">
        <Container>
            <Row>
                <Col className="mt-5 pt-5">
                    <h2 className="h1 ambit font-weight-bold primary-blue-text mb-5">APEXX Analytics</h2>
                    <p>
                        The APEXX Analytics reporting facility is the most powerful and customisable tool of it's kind in the
                        world. The key to amazing and precise data analysis is powerful mapping and visualisation. Our
                        backend analytics is founded on these two principles, and can instantly render and illustrate
                        billions of transactions instantly in a comprehensible format. This gives merchants powerful and
                        meaningful insights to their business.
                    </p>
                </Col>
            </Row>
        </Container>
    </ApexxAnalyticsWrapper>
)

export default ApexxAnalytics