import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/ReportingPageNew/HeroSection/HeroSection"
import ApexxAnalytics from "../components/ReportingPageNew/ApexxAnalytics/ApexxAnalytics"
import ApexxReportingFeatures from "../components/ReportingPageNew/ApexxReportingFeatures/ApexxReportingFeatures"
import PaymentExperts from "../components/ReportingPageNew/PaymentExperts/PaymentExperts"
import SEO from "../components/Global/SEO/seo"

const ReportingPage = () => (
  <Layout
    headerAbsolute
    headerTransparent
    headerMobile={'stay-absolute secondPrimary'}
    whiteToggler
    textlight
    logoDark={false}
    helpSectionColor={'white-bg'}
  >
    <SEO page="analytics" />
    <HeroSection />
    <ApexxAnalytics />
    <ApexxReportingFeatures />
    <PaymentExperts />
  </Layout>
)

export default ReportingPage
