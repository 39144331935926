import styled from "styled-components";

export const BinaryCodeWrapper = styled.div`
    font-size: 1.25em;
    cursor: default;
    color: #6FB3D7;
    user-select: none;
    white-space: nowrap;

    @media (max-width: 767px) {
        font-size: 1em;
    }
`