import styled from "styled-components"

export const StyledPaymentExperts = styled.section`

    background: linear-gradient(180deg, rgba(116, 151, 237, 0.3) 0%, rgba(85, 186, 164, 0.3) 100%);

    .get-in-touch-btn{
        font-size: 1.25rem;
    }

`;
