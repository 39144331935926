import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { StyledApexxReportingFeatures } from "./ApexxReportingFeatures.styles"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ApexxReportingFeatures = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: {eq: "reporting"}, 
          name: {regex: "/diagram-/"}
        }, 
          sort: {fields: name, order: ASC}
        ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `)
  return (
    <StyledApexxReportingFeatures className="py-lg-5 px-4" id="reporting-features">
      <Container className="py-5">
        <Row className="py-5 mb-lg-3 px-lg-0">
          <Col lg={6} className="d-flex flex-column justify-content-start pr-lg-5">
            <h2 className="h3 mb-5 primary-blue-text font-weight-bold ambit"><span>Customise</span></h2>
            <Img fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} className={`${data.allFile.edges[0].node.name} d-block d-lg-none mb-5`} />
            <p>
              The APEXX model is built on the understanding that one size does
              not fit all merchants. In order to get meaningful data
              visualization from your payment provider the ability to customise
              is essential. This is one of the key benefits of APEXX Analytics.
              It delivers an unrivalled ability to change your backend analytics
              to represent the data points that are meaningful to your business,
              giving you a single snapshot of all the important aspects of your
              payments performance whilst excluding extraneous data that is
              unimportant to you
            </p>
          </Col>
          <Col lg={6} className="pt-5 d-none d-lg-block">
            <Img fluid={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid} className={`${data.allFile.edges[0].node.name} d-none d-lg-block`} />
          </Col>
        </Row>
        <Row className="py-5 mb-lg-3">
          <Col
            lg={(6, { order: 2 })}
            className="d-flex flex-column justify-content-start pl-lg-5 "
          >
            <h2 className="h3 mb-5 primary-blue-text font-weight-bold ambit"><span>Compare</span></h2>
            <Img fluid={data.allFile.edges[1] && data.allFile.edges[1].node.childImageSharp.fluid} className={`${data.allFile.edges[1].node.name} d-block d-lg-none mb-5`} />
            <p>
              APEXX Payment Analytics extracts, prepares, integrates and deliver
              your payments data in an easy-to-use User Interface for both
              online and in-store transactions, providing you the insights you
              need to manage every payments related aspect of your business.
            </p>

            <p>
              In addition to this it enables you to map the performance of every
              integrated acquirer, PSP, and APM in a seamless interface allowing
              direct performance comparison of your providers in real time.
              This, in turn, gives you the data required to direct you
              transaction to the most beneficial provider possible.
            </p>
          </Col>
          <Col lg={6} className="pt-5 d-none d-lg-block">
            <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid} className={`${data.allFile.edges[1].node.name} d-none d-lg-block`} />
          </Col>
        </Row>
        <Row className="py-5">
          <Col lg={6} className="d-flex flex-column justify-content-start pr-lg-5 ">
            <h2 className="h3 mb-5 primary-blue-text font-weight-bold ambit"><span>Identify</span></h2>
            <Img fluid={data.allFile.edges[2] && data.allFile.edges[2].node.childImageSharp.fluid} className={`${data.allFile.edges[2].node.name} d-block d-lg-none mb-5`} />
            <p>
              Every transaction that passes through the APEXX Gateway represents
              a data rich opportunity for merchants to better understand their
              customers, their markets, and their business. The aim of APEXX
              Analytics is to give merchants the ability to aggregate, order,
              and understand the data that is collected and retained through the
              APEXX Gateway.
            </p>
            <p>
              The more you can understand about your customer as a business, the
              better you can function as a business. The APEXX Analytics
              platform aggregates essential information such as amount, currency
              and clearing status, as well as collecting metadata like customer
              and device information, geo-location, issuer country, token
              information which can then be filtered and produced in
              customisable reports.
            </p>
          </Col>
          <Col lg={6} className="pt-5 d-none d-lg-block">
            <Img fluid={data.allFile.edges[2].node.childImageSharp.fluid} className={`${data.allFile.edges[2].node.name} d-none d-lg-block`} />
          </Col>
        </Row>
      </Container>
    </StyledApexxReportingFeatures>
  )
}

export default ApexxReportingFeatures
