import styled from "styled-components"

export const StyledApexxReportingFeatures = styled.section`
  h2{

    span{
      display: inline-block;
      &::after {
        content: '';
        height: 15px;
        width: 100%;
        background-color: #18EDD9;
        opacity: 0.3;
        display: block;
        position: relative;
        top: -14px;
        z-index: -1;
      }
    }
  }

  
    .diagram-1, .diagram-2, .diagram-3{
      
      animation-name: levitate;
      animation-duration: 5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out ease-in-out;
      @media (min-width: 992px){
        position: relative;
        width: calc(100% + 150px);

        &.diagram-2{
          right: 150px;
        }
      }
    }

  @keyframes levitate {
      from {
          -webkit-transform: translateY(0px);
      }
      50% {
          -webkit-transform: translateY(20px);
      }
      to {
          -webkit-transform: translateY(0px);
      }
  }

`
