import React from "react"
import { StyledPaymentExperts } from "./PaymentExperts.styles"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"

const PaymentExperts = () => {
  return (
    <StyledPaymentExperts className="py-lg-5 py-3 px-4 text-center d-lg-block">
      <h2 className="h1 mt-5 mb-5 primary-blue-text font-weight-bold ambit">
        Let’s connect you to a payments expert
      </h2>
      <Link to={LINKS.contactUs}>
        <ApButton variant="green" className="mb-5 px-5 get-in-touch-btn font-weight-bold">
          Get in touch
        </ApButton>
      </Link>
    </StyledPaymentExperts>
  )
}

export default PaymentExperts
