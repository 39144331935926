import styled from "styled-components";

export const HeroSectionWrapper = styled.section`
    background: linear-gradient(180deg, #3B5CA7 1.22%, #428AC9 99.11%);
    height: 100vh;

    @media (min-width: 992px){
        h1 {
            font-size: 4rem;
        }
    }

    @media (max-width: 991px){
        height: auto;
        .hero-container {
            margin-top: 75px;
        }        
    }
    .intro-copy{
        font-size: 1.25rem;
        @media (min-width: 992px){
            max-width: 525px;
        }
    }

    .hero-image{
        position: relative;
        left: 25%;
        animation-name: levitate;
        animation-duration: 3.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out ease-in-out;
        z-index: 1;

        @media (max-width: 991px){
            left: auto;
        }
    }

    .learn-more-button{
      font-size: 1.25rem;
      line-height: 1.875rem;
      @media (max-width: 991px){
        width: 100%;
      }
    }

    @keyframes levitate {
        from {
            -webkit-transform: translateY(0px);
        }
        45% {
            -webkit-transform: translateY(20px);
        }
        to {
            -webkit-transform: translateY(0px);
        }
    }

`