import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { HeroSectionWrapper } from "./HeroSection.styles"
import loadable from "@loadable/component"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"
import HeroImage from "./HeroImage"
import BinaryCode from "./BinaryCode"

const TextTransition = loadable(() => import("react-text-transition"))

const HeroSection = () => {

    const [index, setIndex] = useState(0)
    useEffect(() => {
      const intervalId = setInterval(() => {
        setIndex((index) => index + 1)
      }, 3000)
      return () => {
        clearInterval(intervalId)
      }
    }, [])
    const TEXTS = [
      "Customer Behaviour",
      "Acquirer Performance",
      "Commercial Benefits",
    ]
    const colorOptions = [
      "color-sky-blue",
      "blue-aqua",
      "color-sky-blue",
    ]

    return(
        <HeroSectionWrapper className="text-white d-flex flex-column justify-content-lg-center">
            <Container fluid className="hero-container px-4">
                <Row>
                    <Col lg={{ span: 5, offset: 1 }} xs={12}>
                        <h1 className="mb-4 mt-5 text-white font-weight-bold">
                            Connect to your
                            <br />
                            <span>
                            <TextTransition
                                className={colorOptions[index % colorOptions.length]}
                                text={TEXTS[index % TEXTS.length].split(" ")[0]}
                            />
                            <TextTransition
                                className={colorOptions[index % colorOptions.length]}
                                text={TEXTS[index % TEXTS.length].split(" ")[1]}
                            />
                            </span>
                        </h1>
                        <p className="text-white mb-4 intro-copy mr-lg-5">
                            We enable enterprise customers to access the entire global payment ecosystem via a single, trusted, and scalable platform.
                        </p>
                        <Link
                            to={LINKS.contactUs}
                        >
                            <ApButton
                                variant="lightGreen"
                                className="mt-4 learn-more-button"
                                role="link"
                            >
                                Learn More
                            </ApButton>
                        </Link>
                    </Col>
                    <Col lg={6} className="mt-5 mt-lg-0 mb-5 mb-lg-0 d-flex align-items-center">
                        <HeroImage/>
                        <BinaryCode numCount={15} binaryStyles={{ position: 'absolute', top: 0, left: "20%" }} />
                        <BinaryCode numCount={15} binaryStyles={{ position: 'absolute', top: 0, left: "60%" }} classList="d-none d-sm-block"/>
                        <BinaryCode numCount={25} binaryStyles={{ position: 'absolute', top: "20%", left: "15%" }} classList="d-none d-md-block"/>
                        <BinaryCode numCount={25} binaryStyles={{ position: 'absolute', top: "20%", left: "80%" }} />
                        <BinaryCode numCount={20} binaryStyles={{ position: 'absolute', top: "40%", left: "5%" }} />
                        <BinaryCode numCount={20} binaryStyles={{ position: 'absolute', top: "60%", left: "8%" }} />
                        <BinaryCode numCount={25} binaryStyles={{ position: 'absolute', top: "75%", left: "0%" }} />
                        <BinaryCode numCount={20} binaryStyles={{ position: 'absolute', top: "80%", left: "60%" }} classList="d-none d-sm-block"/>
                        <BinaryCode numCount={15} binaryStyles={{ position: 'absolute', bottom: "0", left: "80%" }} />
                        <BinaryCode numCount={25} binaryStyles={{ position: 'absolute', bottom: "0", left: "20%" }} classList="d-none d-sm-block"/>
                    </Col>
                </Row>
            </Container>
            
        </HeroSectionWrapper>
    )
};

export default HeroSection
